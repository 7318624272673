<template>
    <section class="contact-section section-space-b">
            <div class="container">
                <div class="row section-space-b">
                    <div class="col-lg-7">
                        <div class="contact-form-wrap">
                            <div class="section-head-sm">
                                <h2 class="mb-2">{{ contactData.title }}</h2>
                                <p>{{ contactData.subTitle }}</p>
                            </div>
                            <div class="form">
                                <div class="row g-gs">
                                    <div class="col-lg-6">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" id="floatingInputName" placeholder="Name">
                                            <label for="floatingInputName">Your name</label>
                                        </div><!-- end form-floating -->
                                    </div><!-- end col -->
                                    <div class="col-lg-6">
                                        <div class="form-floating">
                                            <input type="email" class="form-control" id="floatingInputEmail" placeholder="name@example.com">
                                            <label for="floatingInputEmail">Email address</label>
                                        </div><!-- end form-floating -->
                                    </div><!-- end col -->
<!--                                    <div class="col-lg-12">-->
<!--                                        <div class="form-floating">-->
<!--                                            <input type="text" class="form-control" id="floatingInputPhoneNumber" placeholder="Username">-->
<!--                                            <label for="floatingInputPhoneNumber">Phone number</label>-->
<!--                                        </div>&lt;!&ndash; end form-floating &ndash;&gt;-->
<!--                                    </div>&lt;!&ndash; end col &ndash;&gt;-->
                                    <div class="col-lg-12">
                                        <div class="form-floating">
                                            <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                                            <label for="floatingTextarea">Type message here...</label>
<!--                                            <label for="floatingTextarea">Any questions feel free to contact our Customer Support.</label>-->
                                        </div><!-- end form-floating -->
                                    </div><!-- end col -->
                                    <div class="col-lg-12">
                                        <button class="btn btn-dark"  @click="sendMessage" disabled>{{ contactData.btnText }}</button>
                                    </div><!-- end col -->
                                </div><!-- end row -->
                            </div>
                        </div><!-- end card -->
                    </div><!-- end col-lg-7 -->
                    <div class="col-lg-5">
                        <div class="contact-info ps-lg-4 ps-xl-5">
                            <div class="section-head-sm">
                                <h2 class="mb-2">{{ contactData.titleTwo }}</h2>
<!--                                <p>{{ contactData.content }}</p>-->
                            </div>
                            <ul class="contact-details">
                                <li class="d-flex align-items-center" v-for="list in contactData.contactList" :key="list.id">
                                    <em class="ni icon-btn icon-btn-s1" :class="list.icon"></em>
                                    <div class="ms-4">
                                        <strong class="d-block text-black">{{ list.title }}</strong>
                                        <span>{{ list.subTitle }}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div><!-- end col -->
                </div><!-- end row -->
                <!-- google map -->
<!--                <GoogleMap></GoogleMap>-->
            </div><!-- end container -->
        </section><!-- end contact-section -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'

export default {
  name: 'ContactSection',
  data () {
    return {
      SectionData,
      contactData: {
        title: 'Contact Us',
        titleTwo: 'Find Us There',
        subTitle: "Have a question? Need help? Don't hesitate, drop us a line",
        btnText: 'Send Message',
        content: 'Collaboratively administrate channels whereas virtual. Objectively seize scalable metrics whereas proactive e-services.',
        contactList: [
          // {
          //   id: 1,
          //   icon: 'ni-mobile',
          //   title: 'Phone:',
          //   subTitle: '(123) 123-456'
          // },
          // {
          //   id: 2,
          //   icon: 'ni-globe',
          //   title: 'Web:',
          //   subTitle: 'www.softnio.com'
          // },
          {
            id: 3,
            icon: 'ni-mail',
            title: 'Email:',
            subTitle: 'info@gdmining.com'
          },
          {
            id: 4,
            icon: 'ni-globe',
            title: 'Location:',
            subTitle: '590 Kingston Road, London, England, SW20 8DN'
          },
        ]
      },
      sidebarData: {
        sidebarWidget: {
          title: 'Search',
          inputText: 'Search post'
        },
        sidebarWidgetTwo: {
          title: 'Kamran Ahmed',
          img: require('@/images/thumb/avatar-4.jpg'),
          content: 'I make art with the simple goal of giving you something pleasing to look at for a few seconds.',
          icons: [
            {
              id: 1,
              icon: 'ni-facebook-f',
              path: ''
            },
            {
              id: 2,
              icon: 'ni-twitter',
              path: ''
            },
            {
              id: 3,
              icon: 'ni-instagram',
              path: ''
            },
            {
              id: 4,
              icon: 'ni-pinterest',
              path: ''
            }
          ]
        },
        sidebarWidgetThree: {
          title: 'Categories',
          catList: [
            {
              id: 1,
              title: 'Art',
              path: '/blog'
            },
            {
              id: 2,
              title: ' Virtual Worlds',
              path: '/blog'
            },
            {
              id: 3,
              title: 'Collectibles',
              path: '/blog'
            },
            {
              id: 4,
              title: 'Music',
              path: '/blog'
            },
            {
              id: 5,
              title: 'Games',
              path: '/blog'
            },
            {
              id: 6,
              title: 'Domains',
              path: '/blog'
            },
            {
              id: 7,
              title: 'Memes',
              path: '/blog'
            }
          ]
        },
        sidebarWidgetFour: {
          title: 'Recent Post',
          postList: [
            {
              id: 1,
              img: require('@/images/thumb/small-img.jpg'),
              title: 'Announcing Our $100m Raise',
              date: 'July 13, 2021',
              path: '/blog'
            },
            {
              id: 2,
              img: require('@/images/thumb/small-img-2.jpg'),
              title: 'Edition365: A Portrait Of The Year That',
              date: 'July 13, 2021',
              path: '/blog'
            },
            {
              id: 3,
              img: require('@/images/thumb/small-img-3.jpg'),
              title: 'Artist interview: Arran Schonberg',
              date: 'July 13, 2021',
              path: '/blog'
            }
          ]
        },
        sidebarWidgetFive: {
          title: 'Tags',
          tagList: [
            {
              id: 1,
              title: 'Virtual World',
              path: '/blog'
            },
            {
              id: 2,
              title: 'Art',
              path: '/blog'
            },
            {
              id: 3,
              title: 'Vision',
              path: '/blog'
            },
            {
              id: 4,
              title: 'Music',
              path: '/blog'
            },
            {
              id: 5,
              title: 'Domains',
              path: '/blog'
            },
            {
              id: 6,
              title: 'Print',
              path: '/blog'
            },
            {
              id: 7,
              title: 'Programming',
              path: '/blog'
            }
          ]
        }
      },
    }
  },
  methods: {
    sendMessage(){
      console.log('sendMessage')
      this.$message.info('Send Message Event')
    }
  }
}
</script>
