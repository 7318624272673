<template>
  <swiper
      :modules="modules"
      :slides-per-view="3"
      :breakpoints="{
    0: {
      slidesPerView: 1
    },
    767: {
      slidesPerView: 2
    },
    992: {
      slidesPerView: 3
    }
  }"
      :pagination="{ clickable: true }">
    <swiper-slide v-for="(item, index) in OurDateCenterList" :key="index">
      <div class="card work-process border-0 rounded shadow">
        <div class="card-body text-center">
          <div class="ratio  img_inner-box">
            <template v-if="item.type === 'video'">
              <YouTube
                  :src="item.img"
                  @ready="onReady"
                  ref="youtube" class="img_inner" v-show="showYouTube"/>
            </template>
            <img :src="item.img" class="img_inner" v-else>
          </div>
          <h6 class="title">Security/Fire Protection</h6>
          <p style="font-size: 13px;">
            24/7 on-site guards, intrusion detection systems, IP-DVR cameras.
          </p>
          <div class="py-2">
            <div class="card-action-info">
              <span><em class="ni ni-calender-date me-1"></em>25 Apr 2020</span>
              <span>
                                        <span class="me-3"><em class="ni ni-comments me-1"></em>20</span>
                                        <span><em class="ni ni-heart me-1"></em>38</span>
                                    </span>
            </div><!-- end card-action-info -->
          </div>

        </div>
      </div>
    </swiper-slide>
    <!-- pagination -->
  </swiper>
</template>
<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'

// core version + navigation, pagination modules:
import SwiperCore, {Pagination} from 'swiper';

// configure Swiper to use modules
SwiperCore.use([Pagination]);

// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';

export default {
  name: 'BlogSectionSlider',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      SectionData,
      OurDateCenterList: [
        {
          img: require('../../images/imgs/img1.png'),
          title: 'Data Center Locations',
          content: 'Our Mining Data Centers are located in Brazil & Windhoek & Kazakhstan.'
        },
        {
          img: 'https://www.youtube.com/watch?v=' + this.youtubeId,
          title: 'Data Center Hardware',
          content: '15,000+ The newest ASIC miner, GPU rigs are Ready to mine.',
          type: 'video'
        },
        {
          img: require('../../images/imgs/img3.png'),
          title: 'Power System',
          content: 'We use Hydropower & Wind power, Total capacity of 300,000 KW.0.05 $/kWh.'
        },
        {
          img: require('../../images/imgs/img4.png'),
          title: 'Cooling System',
          content: 'Air cooling system and Water cooling system keeping temperature:20-24℃.'
        },
        {
          img: require('../../images/imgs/img5.png'),
          title: 'Security/Fire Protection',
          content: ' 24/7 on-site guards, intrusion detection systems, IP-DVR cameras.'
        },
        {
          img: require('../../images/imgs/img6.png'),
          title: 'Security/Fire Protection',
          content: '24/7 on-site guards, intrusion detection systems, IP-DVR cameras.'
        }
      ],
      showYouTube: false
    }
  },
  setup() {
    return {
      modules: [Pagination]
    }
  },
  mounted() {
    setTimeout(() => {
      console.log(document.querySelector("iframe"))
      document.querySelector("iframe").style.width = "100%";
      document.querySelector("iframe").style.height = "100%";
      this.showYouTube = true;
    }, 2000)
  },
  methods: {
    onReady() {
      console.log(237, this.$refs.youtube)
    },
  }
}
</script>

<style lang="scss" scoped>
.card {
  overflow: hidden;
}

.card-body {
  padding: 0;

  .title, p {
    padding: 0.75rem;
  }

  .title {
    margin-top: 0.75rem;
  }

  .card-action-info{
    padding:0 0.75rem 0.5rem;
    span{
      font-size: 0.8125rem;
      //font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8492A6;
      line-height: 1.25rem;

    }

  }
}

.img_inner {
  width: 100% !important;
  height: 180px !important;
}
</style>
