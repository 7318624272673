<template>
  <div class="about-us">
    <!-- header  -->
    <header class="header-section has-header-main">
      <!-- Header main -->
      <HeaderMain></HeaderMain>
    </header>
    <!-- About section  -->
    <section class="about-section pt-5 mt-3">
      <div class="container">
        <div class="row align-items-center flex flex-lg-row-reverse justify-content-center">
          <div class="col-lg-6 mb-5 mb-lg-0 col-sm-9 ps-xl-5">
            <img src="@/images/tmp/location5.jpg" alt="" class="w-100  ms-xl-4" style="    border-radius: 1.875rem;">
          </div><!-- end col-lg-6 -->
          <div class="col-lg-6 pe-lg-5">
            <div class="about-content-wrap">
<!--              <h2 class="mb-3">Who We Are</h2>-->
              <h2 class="mb-3">GDMining - Innovation meets reliability</h2>
              <br/>
              <h3 class="mb-3">Introduction:</h3>
              <p>Welcome to GDMining, where innovation meets reliability. Established in 2021 in St Asaph, United Kingdom, our mission at GDMining is to redefine the landscape of cloud mining, providing users like you with a seamless and rewarding mining experience.</p>
              <br/>
<!--              <h3 class="mb-3">Our Strengths:</h3>-->
<!--              <br/>-->
              <p>From the very beginning, our focus has been on leveraging the latest technology and renewable energy sources to power our mining operations, ensuring maximum profitability for our users while minimizing our environmental footprint.</p>
              <br/>
              <p>At GDMining, accessibility is key. We believe that everyone should have the opportunity to participate in the exciting world of cryptocurrency mining, regardless of their technical expertise or financial resources. That's why we offer free cloud mining services, making it easy for anyone to get started on their mining journey.</p>
              <br/>
              <p>With six state-of-the-art mining facilities located strategically around the globe, including in Canada, Iceland, and Kazakhstan, we have built a robust infrastructure that ensures the reliability and efficiency of our operations. This global presence allows us to serve users from over 200 countries and regions, providing them with the support and resources they need to succeed.</p>
              <br/>
              <h3 class="mb-3">Why Choose GDMining:</h3>
              <br/>
              <p> What truly sets GDMining apart is our commitment to our users. As a member of the GDMining community, you can expect unparalleled customer support and guidance every step of the way. Whether you're a seasoned miner or just starting out, we are here to help you navigate the world of cryptocurrency mining and achieve your goals.</p>
              <br/>
              <p>As we look to the future, we remain steadfast in our dedication to innovation and excellence. We are constantly exploring new opportunities and technologies to improve our services and enhance the mining experience for our users. Join us on this exciting journey as we continue to revolutionize the world of mining, one block at a time.</p>
              <br/>
              <p>Thank you for choosing GDMining. Together, we will shape the future of mining.</p>

            </div>
          </div><!-- end col-lg-6 -->
        </div><!-- end row -->
      </div><!-- end container -->
    </section><!-- end about-section -->


    <!-- funFact  -->
    <FunFactSection classname="col-lg-3 col-sm-6 col-6" :items="funfactList" cardbodyclass="px-0" :imgstyle="{width: '2.1875rem'}"></FunFactSection>

<!--    <section class="section-space-b date-center-section">-->
<!--      <div class="container">-->
<!--        <SectionHeading classname="text-center" text="Our Date Center" content="Services That We Continue To Improve" isMargin="mb-3"></SectionHeading>-->
<!--        &lt;!&ndash; section heading &ndash;&gt;-->
<!--        <OurDateCenterSectionSlider></OurDateCenterSectionSlider>-->
<!--      </div>&lt;!&ndash; .container &ndash;&gt;-->
<!--    </section>&lt;!&ndash; end blog-section &ndash;&gt;-->

<!--    <section class="section-space-b">-->
<!--      <div class="container">-->
<!--        &lt;!&ndash; section heading &ndash;&gt;-->
<!--        <SectionHeading classname="text-center" text="Get In Touch with Us" content="" isMargin="mb-3"></SectionHeading>-->
<!--        <div class="row">-->
<!--          <ContactSection></ContactSection>-->
<!--        </div>-->
<!--      </div>&lt;!&ndash; .container &ndash;&gt;-->
<!--    </section>-->


    <!-- Footer  -->
    <Footer classname="bg-cus on-dark"></Footer>
  </div><!-- end page-wrap -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import OurDateCenterSectionSlider from "@/components/n1/OurDateCenterSectionSlider";
import ContactSection from "@/components/n1/ContactSection"
import HeaderMain from "@/components/n1/HeaderMain"
import Footer from "@/pages/n1/Footer"
import FunFactSection from "@/components/n1/FunFactSection"


export default {
  name: 'AboutUs',
  components: {
    OurDateCenterSectionSlider,
    ContactSection,
    HeaderMain,
    Footer,
    FunFactSection
  },
  data() {
    return {
      SectionData,
      funfactList: [
        {
          id: 1,
          icon: require('@/images/aboutus-img_1.png'),
          title: '500K+',
          subTitle: 'Active Members'
        },
        {
          id: 2,
          icon: require('@/images/aboutus-img_2.png'),
          title: '200+',
          subTitle: 'Countries Supported'
        },
        {
          id: 3,
          icon: require('@/images/aboutus-img_3.png'),
          title: '10M+',
          subTitle: 'Total Deposit'
        },
        {
          id: 4,
          icon: require('@/images/aboutus-img_4.png'),
          title: '13M+',
          subTitle: 'Total Withdraw'
        }
      ],
      // youtubeId: "95uY09yVxAs"
      // youtubeId: "IvEzCllWrgs"
    }
  },
  mounted() {
    setTimeout(() => {
      console.log(document.querySelector("iframe"))
      document.querySelector("iframe").style.width = "100%";
      document.querySelector("iframe").style.height = "100%";
    }, 1000)
  },
  methods: {
    onReady() {
      console.log(237, this.$refs.youtube)
    },
  }
}
</script>


<style scoped lang="scss">
.bg-cus {
  background-color: #ffe5d0;
}

.about-us {
  .about-section {

  }

  .date-center-section {
    .card {
      overflow: hidden;
    }

    .card-body {
      padding: 0;

      .title, p {
        padding: 0.75rem;
      }

      .title {
        margin-top: 0.75rem;
      }

      .card-action-info{
        padding:0 0.75rem 0.5rem;
        span{
          font-size: 0.8125rem;
          //font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8492A6;
          line-height: 1.25rem;

        }

    }
    }
  }

  .img_inner {
    width: 100% !important;
    height: 180px !important;
  }
}

</style>
